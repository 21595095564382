h1 {
    display: table;
    margin: 40px auto;
    color: #fff;
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    letter-spacing: 10px;
  }
  
  form {
    display: table;
    margin: 20px auto;
    border-radius: 5px;
    padding: 5px;
    width:100%;
    background-color: rgba(191,191,191,0.3);
  
    input{
      border-radius: 5px;
      font-family: "Poppins", sans-serif;
      box-sizing: border-box;
      display: block;
      padding: 5px;
      color: #999;
      margin-bottom: 20px;
      margin-left: 10px;
      border: none;
      // border: 1px solid #123475;
      width: 50%;
      transition: all 0.2s ease-in-out;
    }
    textarea {
      font-family: "Poppins", sans-serif;
      border-radius: 5px;
      box-sizing: border-box;
      margin-left: 10px;
      display: block;
      border: none;
      padding: 5px;
      width: 95%;
      margin-bottom: 20px;
      font-size: 18px;
      outline: none;
      transition: all 0.2s ease-in-out;
  
      &::placeholder {
        transition: all 0.2s ease-in-out;
        color: #999;
        font-family: "Poppins", sans-serif;
      }
  
      &:focus,
      &.populated {
        padding-top: 28px;
        padding-bottom: 12px;
  
        &::placeholder {
          color: transparent;
        }
      }
    }
  
    textarea {
      height: 100px;
    }
  
    button {
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
      border-radius: 3px;
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
      background-color:#082660;
      background-size: 200% auto;
      padding:10px;
      font-weight: 600;
      color: #fff;
      width:30%;
      border: none;
      cursor: pointer;
      display: inline-block;
      transition: all 0.3s linear;
  
      &:hover {
        background: #BFBFBF;
        cursor: pointer;
      }
    }
  }